// routes
import Generacion from 'Routes/generacion';
import Ciclo from 'Routes/ciclo-escolar';
import Carrera from 'Routes/carrera';
import Cuatrimestre from 'Routes/cuatrimestre';
import Matricula from 'Routes/eficacia/matricula';
import Usuario from 'Routes/usuario';
import Perfil from 'Routes/perfil';
import Aprovechamiento from 'Routes/eficacia/aprovechamiento';
import Reprobacion from 'Routes/eficacia/reprobacion';
import Desercion from 'Routes/eficacia/desercion';
import Egreso from 'Routes/eficacia/egreso';
import Dashboard from 'Routes/dashboard';


let routes = [
    {
        path: 'generacion',
        component: Generacion
    },
    {
        path: 'ciclo-escolar',
        component: Ciclo
    },
    {
        path: 'carrera',
        component: Carrera
    },
    {
        path: 'cuatrimestre',
        component: Cuatrimestre
    },
    {
        path: 'eficacia/matricula',
        component: Matricula
    },
    {
        path: 'usuario',
        component: Usuario
    },
    {
        path: 'perfil',
        component: Perfil
    },
    {
        path: 'eficacia/aprovechamiento',
        component: Aprovechamiento
    },
    {
        path: 'eficacia/reprobacion',
        component: Reprobacion
    },
    {
        path: 'eficacia/desercion',
        component: Desercion
    },
    {
        path: 'eficacia/egreso',
        component: Egreso
    },
    {
        path: 'dashboard',
        component: Dashboard
    }
]

export default routes;
