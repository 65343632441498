import React, { useState, useEffect }  from 'react';
import { AppEnv } from 'App/AppConfig';

const environment = AppEnv.confCurrent;
const contextPath = environment.contextPath;


let sidebarMenu = {
    category1: [
        {
            "menu_title": "sidebar.dashboard",
            "menu_icon": "zmdi zmdi-apps",
            "path": contextPath + "/app/dashboard",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "sidebar.configuracion",
            "menu_icon": "zmdi zmdi-settings",
            "new_item": false,
            "child_routes": [
                {
                    "menu_title": "sidebar.usuarios",
                    "path": contextPath + "/app/usuario/list",
                },
                {
                    "menu_title": "sidebar.perfiles",
                    "path": contextPath + "/app/perfil/list",
                }
            ]
        },
        {
            "menu_title": "sidebar.indicadores",
            "menu_icon": "zmdi zmdi-chart",
            "type_multi": true,
            "new_item": false,
            "child_routes": [
                {
                    "menu_title": "sidebar.eficacia",
                    "child_routes": [
                        {
                            "path": contextPath + "/app/eficacia/matricula/list",
                            "menu_title": "sidebar.matricula"
                        },
                        {
                            "path": contextPath + "/app/eficacia/aprovechamiento/list",
                            "menu_title": "sidebar.aprovechamiento"
                        },
                        {
                            "path": contextPath + "/app/eficacia/reprobacion/list",
                            "menu_title": "sidebar.reprobacion"
                        },
                        {
                            "path": contextPath + "/app/eficacia/desercion/list",
                            "menu_title": "sidebar.desercion"
                        },
                        {
                            "path": contextPath + "/app/eficacia/egreso/list",
                            "menu_title": "sidebar.egreso"
                        }
                    ]
                }
            ]
        },
        {
            "menu_title": "sidebar.ciclos",
            "menu_icon": "zmdi zmdi-format-align-left",
            "path": contextPath + "/app/ciclo-escolar/list",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "sidebar.generaciones",
            "menu_icon": "zmdi zmdi-graduation-cap",
            "path": contextPath + "/app/generacion/list",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "sidebar.carreras",
            "menu_icon": "zmdi zmdi-view-carousel",
            "path": contextPath + "/app/carrera/list",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "sidebar.cuatrimestres",
            "menu_icon": "zmdi zmdi-floppy",
            "path": contextPath + "/app/cuatrimestre/list",
            "new_item": false,
            "child_routes": null
        }
    ]
}

export default sidebarMenu
