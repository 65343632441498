/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

// rct page loader
import RctPageLoader from 'Components/RctPageLoader/RctPageLoader';



const AsyncLoginComponent = Loadable({
	loader: () => import("Routes/login"),
	loading: () => <RctPageLoader />,
});


// Sistema Indicadores
// generaciones
const AsyncListGeneracionComponent = Loadable({
	loader: () => import("Routes/generacion/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddGeneracionComponent = Loadable({
	loader: () => import("Routes/generacion/add"),
	loading: () => <RctPageLoader />,
});

// generaciones
const AsyncListCicloComponent = Loadable({
	loader: () => import("Routes/ciclo-escolar/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddCicloComponent = Loadable({
	loader: () => import("Routes/ciclo-escolar/add"),
	loading: () => <RctPageLoader />,
});

// Carreras
const AsyncListCarreraComponent = Loadable({
	loader: () => import("Routes/carrera/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddCarreraComponent = Loadable({
	loader: () => import("Routes/carrera/add"),
	loading: () => <RctPageLoader />,
});

// Cuatrimestres
const AsyncListCuatrimestreComponent = Loadable({
	loader: () => import("Routes/cuatrimestre/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddCuatrimestreComponent = Loadable({
	loader: () => import("Routes/cuatrimestre/add"),
	loading: () => <RctPageLoader />,
});

// Matriculas
const AsyncListMatriculaComponent = Loadable({
	loader: () => import("Routes/eficacia/matricula/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddMatriculaComponent = Loadable({
	loader: () => import("Routes/eficacia/matricula/add"),
	loading: () => <RctPageLoader />,
});

// Usuarios
const AsyncListUsuarioComponent = Loadable({
	loader: () => import("Routes/usuario/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddUsuarioComponent = Loadable({
	loader: () => import("Routes/usuario/add"),
	loading: () => <RctPageLoader />,
});

// Perfiles
const AsyncListPerfilComponent = Loadable({
	loader: () => import("Routes/perfil/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddPerfilComponent = Loadable({
	loader: () => import("Routes/perfil/add"),
	loading: () => <RctPageLoader />,
});

// Aprovechamiento
const AsyncListAprovechamientoComponent = Loadable({
	loader: () => import("Routes/eficacia/aprovechamiento/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddAprovechamientoComponent = Loadable({
	loader: () => import("Routes/eficacia/aprovechamiento/add"),
	loading: () => <RctPageLoader />,
});

// Reprobación
const AsyncListReprobacionComponent = Loadable({
	loader: () => import("Routes/eficacia/reprobacion/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddReprobacionComponent = Loadable({
	loader: () => import("Routes/eficacia/reprobacion/add"),
	loading: () => <RctPageLoader />,
});


// Desercion
const AsyncListDesercionComponent = Loadable({
	loader: () => import("Routes/eficacia/desercion/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddDesercionComponent = Loadable({
	loader: () => import("Routes/eficacia/desercion/add"),
	loading: () => <RctPageLoader />,
});

// Egreso
const AsyncListEgresoComponent = Loadable({
	loader: () => import("Routes/eficacia/egreso/list"),
	loading: () => <RctPageLoader />,
});

const AsyncAddEgresoComponent = Loadable({
	loader: () => import("Routes/eficacia/egreso/add"),
	loading: () => <RctPageLoader />,
});

export {
    AsyncLoginComponent,
    AsyncListGeneracionComponent,
    AsyncAddGeneracionComponent,
    AsyncListCicloComponent,
    AsyncAddCicloComponent,
    AsyncListCarreraComponent,
    AsyncAddCarreraComponent,
    AsyncListCuatrimestreComponent,
    AsyncAddCuatrimestreComponent,
    AsyncListMatriculaComponent,
    AsyncAddMatriculaComponent,
    AsyncListUsuarioComponent,
    AsyncAddUsuarioComponent,
    AsyncListPerfilComponent,
    AsyncAddPerfilComponent,
    AsyncListAprovechamientoComponent,
    AsyncAddAprovechamientoComponent,
    AsyncListReprobacionComponent,
    AsyncAddReprobacionComponent,
    AsyncListDesercionComponent,
    AsyncAddDesercionComponent,
    AsyncListEgresoComponent,
    AsyncAddEgresoComponent
};
