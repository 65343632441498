import React, { useState, useEffect }  from 'react';
import IntlMessages from 'Util/IntlMessages';
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GifLoader from 'react-gif-loader';
import { AppEnv } from 'App/AppConfig';
import ChartConfig from 'App/chart-config';
// card component
import StackedBarChart from 'Components/Charts/StackedBarChart';

/*
const visitorsData = {
   chartData: {
	  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
	  data: [600, 500, 650, 470, 520, 700, 500, 650, 580, 500, 650, 700]
   },
   monthly: 7233,
   weekly: 5529
}

const dataChart = {
	labels: [
		'Aprovechamiento',
		'Egreso y titulación',
		'Deserción',
		'Reprobación',
		'Matrícula'
	],
	datasets: [{
		data: [numberAprovecamiento, numberEgreso, numberDesercion, numberReprobacion, numberMatriucla],
		backgroundColor: [
			ChartConfig.color.secondary,
			ChartConfig.color.yellow,
			ChartConfig.color.warning,
			ChartConfig.color.success,
			ChartConfig.color.danger
		],
		hoverBackgroundColor: [
			ChartConfig.color.purple,
			ChartConfig.color.info,
			ChartConfig.color.dark,
			ChartConfig.color.default,
			ChartConfig.color.primary
		]
	}]
};

const options = {
	legend: {
		display: false,
		labels: {
			fontColor: ChartConfig.legendFontColor
		}
	},
	cutoutPercentage: 50
};
var indicadorOne = [];
indicadorOne.push(
	<div className="support-widget-wrap">
		<div className="text-center py-10">
		   <Doughnut data={dataChart} options={options} height={100} />
		</div>
		<List className="list-unstyled p-0">
			<ListItem className="bg-light px-15 py-0 d-flex justify-content-between align-content-center">
				<p className="mb-0 content-title"><IntlMessages id="sidebar.aprovechamiento" /></p>
				<Badge color="secondary" className="px-4">{numberAprovecamiento}</Badge>
				<IconButton color="default"><i className="ti-eye"></i></IconButton>
			</ListItem>
			<ListItem className="px-15 py-0 d-flex justify-content-between align-content-center">
				<p className="mb-0 content-title"><IntlMessages id="sidebar.egreso" /></p>
				<Badge color="info" className="px-4">{numberEgreso}</Badge>
				<IconButton color="default"><i className="ti-eye"></i></IconButton>
			</ListItem>
			<ListItem className="bg-light px-15 py-0 d-flex justify-content-between align-content-center">
				<p className="mb-0 content-title"><IntlMessages id="sidebar.desercion" /></p>
				<Badge color="warning" className="px-4">{numberDesercion}</Badge>
				<IconButton color="default"><i className="ti-eye"></i></IconButton>
			</ListItem>
			<ListItem className="px-15 py-0 d-flex justify-content-between align-content-center">
				<p className="mb-0 content-title"><IntlMessages id="sidebar.reprobacion" /></p>
				<Badge color="success" className="px-4">{numberReprobacion}</Badge>
				<IconButton color="default"><i className="ti-eye"></i></IconButton>
			</ListItem>
			<ListItem className="bg-light px-15 py-0 d-flex justify-content-between align-content-center">
				<p className="mb-0 content-title"><IntlMessages id="sidebar.egreso" /></p>
				<Badge color="danger" className="px-4">{numberMatriucla}</Badge>
				<IconButton color="default"><i className="ti-eye"></i></IconButton>
			</ListItem>
		</List>
	   <RctCardFooter customClasses="d-flex justify-content-between border-0 align-items-center">
		  <p className="fs-12 mb-0 text-base"></p>
		  <Button variant="contained" color="primary" className="btn-xs text-white"><IntlMessages id="button.assignNow" /><i className="mr-5 zmdi zmdi-refresh pl-2"></i></Button>
	   </RctCardFooter>
	</div>
);*/

export default function HomeDasboard(props) {
	const [environment, setEnvironment] = useState(AppEnv.confCurrent);
	const [data, setData] = useState(new FormData());
	const [showLoadingGif, setShowLoadingGif] = useState(false);
	const [numberAprovecamiento, setNumberAprovecamiento] = useState(0);
	const [numberEgreso, setNumberEgreso] = useState(0);
	const [numberDesercion, setNumberDesercion] = useState(0);
	const [numberReprobacion, setNumberReprobacion] = useState(0);
	const [numberMatriucla, setNumberMatriucla] = useState(0);

	const [porcentajeReprobacion21, setPorcentajeReprobacion21] = useState(0);
	const [porcentajeReprobacion20, setPorcentajeReprobacion20] = useState(0);

	const [totalIngresosMatricula21, setTotalIngresosMatricula21] = useState(0);
	const [totalHombres21, setTotalHombres21] = useState(0);
	const [totalMujeres21, setTotalMujeres21] = useState(0);
	const [totalIngresosMatricula20, setTotalIngresosMatricula20] = useState(0);
	const [totalHombres20, setTotalHombres20] = useState(0);
	const [totalMujeres20, setTotalMujeres20] = useState(0);

	const [totalIngresosMatriculaNI21, setTotalIngresosMatriculaNI21] = useState(0);
	const [totalHombresNI21, setTotalHombresNI21] = useState(0);
	const [totalMujeresNI21, setTotalMujeresNI21] = useState(0);
	const [totalIngresosMatriculaNI20, setTotalIngresosMatriculaNI20] = useState(0);
	const [totalHombresNI20, setTotalHombresNI20] = useState(0);
	const [totalMujeresNI20, setTotalMujeresNI20] = useState(0);

	const [porcentajeDesercion21, setPorcentajeDesercion21] = useState(0);
	const [porcentajeDesercion20, setPorcentajeDesercion20] = useState(0);

	const [promedio21, setPromedio21] = useState(0);
	const [promedio20, setPromedio20] = useState(0);

	const formatDecimals = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
			const negativeSign = amount < 0 ? "-" : "";

			let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;

			return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getIndicadoresCount();
		getPorcentajeReprobacionSepDic();
		getMatriculaSepDic();
		getMatriculaNISepDic();
		getPorcentajeDesercionSepDic();
		getAprovechamientoSepDic();
	},[]);

	const getIndicadoresCount = () => {
		setShowLoadingGif(true);
		const axios = require('axios');
		axios.post(environment.apiHome + 'get/count/indicadores', data)
		.then(function (response) {
			console.log("response: ",response);
			setShowLoadingGif(false);
			if(response.data.id == 1) {
				setNumberAprovecamiento(response.data.data.CountAprovechamiento);
				setNumberEgreso(response.data.data.CountEgreso);
				setNumberDesercion(response.data.data.CountDesercion);
				setNumberReprobacion(response.data.data.CountReprobacion);
				setNumberMatriucla(response.data.data.CountMatricula);
			}
		})
		.catch(function (error) {
			console.log(error);
			toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
			setShowLoadingGif(false);
		});
	}

	const getPorcentajeReprobacionSepDic = () => {
		setShowLoadingGif(true);
		const axios = require('axios');
		axios.post(environment.apiHome + 'get/porcentaje/reprobacion/sep/dic', data)
		.then(function (response) {
			console.log("response reprobacion: ",response);
			setShowLoadingGif(false);
			if(response.data.id == 1) {
				var dataSepDic21 = response.data.data.SepDic21;
				var dataSepDic20 = response.data.data.SepDic20;
				var total_ingresos_21 = 0;
				var total_reprobados_21 = 0;
				var total_ingresos_20 = 0;
				var total_reprobados_20 = 0;

				for (var i = 0; i < dataSepDic21.length; i++) {
					var sepDic21 = dataSepDic21[i];
					total_ingresos_21 =  (parseInt(sepDic21.total_ingresos_alumnos) + total_ingresos_21);
					total_reprobados_21 =  (parseInt(sepDic21.total_reprobados) + total_reprobados_21);
				}

				for (var i = 0; i < dataSepDic20.length; i++) {
					var sepDic20 = dataSepDic20[i];
					total_ingresos_20 =  (parseInt(sepDic20.total_ingresos_alumnos) + total_ingresos_20);
					total_reprobados_20 =  (parseInt(sepDic20.total_reprobados) + total_reprobados_20);
				}

				console.log("total_ingresos_21: ",total_ingresos_21);
				console.log("total_reprobados_21: ",total_reprobados_21);
				console.log("total_ingresos_20: ",total_ingresos_20);
				console.log("total_reprobados_20: ",total_reprobados_20);

				if (total_ingresos_21 > 0 && total_reprobados_21 > 0) {
					var porcentajeRe21 = (total_reprobados_21 / total_ingresos_21) * 100;
					console.log("porcentajeRe21: ",porcentajeRe21);
					setPorcentajeReprobacion21(formatDecimals(porcentajeRe21));
				}

				if (total_ingresos_20 > 0 && total_reprobados_20 > 0) {
					var porcentajeRe20 = (total_reprobados_20 / total_ingresos_20) * 100;
					console.log("porcentajeRe20: ",porcentajeRe20);
					setPorcentajeReprobacion20(formatDecimals(porcentajeRe20));
				}

			}
		})
		.catch(function (error) {
			console.log(error);
			toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
			setShowLoadingGif(false);
		});
	}

	const getMatriculaSepDic = () => {
		setShowLoadingGif(true);
		const axios = require('axios');
		axios.post(environment.apiHome + 'get/matricula/sep/dic', data)
		.then(function (response) {
			console.log("response matriculas: ",response);
			setShowLoadingGif(false);
			if(response.data.id == 1) {
				var dataSepDic21 = response.data.data.SepDic21;
				var dataSepDic20 = response.data.data.SepDic20;
				var total_ingresos_21 = 0;
				var total_hombres_21 = 0;
				var total_mujeres_21 = 0;
				var total_ingresos_20 = 0;
				var total_hombres_20 = 0;
				var total_mujeres_20 = 0;

				for (var i = 0; i < dataSepDic21.length; i++) {
					var sepDic21 = dataSepDic21[i];
					total_ingresos_21 =  (parseInt(sepDic21.total_ingresos_alumnos) + total_ingresos_21);
					total_hombres_21 =  (parseInt(sepDic21.cantidad_ingresos_hombres) + total_hombres_21);
					total_mujeres_21 =  (parseInt(sepDic21.cantidad_ingresos_mujeres) + total_mujeres_21);
				}

				for (var i = 0; i < dataSepDic20.length; i++) {
					var sepDic20 = dataSepDic20[i];
					total_ingresos_20 =  (parseInt(sepDic20.total_ingresos_alumnos) + total_ingresos_20);
					total_hombres_20 =  (parseInt(sepDic20.cantidad_ingresos_hombres) + total_hombres_20);
					total_mujeres_20 =  (parseInt(sepDic20.cantidad_ingresos_mujeres) + total_mujeres_20);
				}

				/*console.log("total_ingresos_21: ",total_ingresos_21);
				console.log("total_hombres_21: ",total_hombres_21);
				console.log("total_mujeres_21: ",total_mujeres_21);
				console.log("total_ingresos_20: ",total_ingresos_20);
				console.log("total_hombres_20: ",total_hombres_20);
				console.log("total_mujeres_20: ",total_mujeres_20);*/

				setTotalIngresosMatricula21(total_ingresos_21);
				setTotalHombres21(total_hombres_21);
				setTotalMujeres21(total_mujeres_21);
				setTotalIngresosMatricula20(total_ingresos_20);
				setTotalHombres20(total_hombres_20);
				setTotalMujeres20(total_mujeres_20);
			}
		})
		.catch(function (error) {
			console.log(error);
			toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
			setShowLoadingGif(false);
		});
	}

	const getMatriculaNISepDic = () => {
		setShowLoadingGif(true);
		const axios = require('axios');
		axios.post(environment.apiHome + 'get/matricula/nuevo/ingreso/sep/dic', data)
		.then(function (response) {
			console.log("response matriculas NI: ",response);
			setShowLoadingGif(false);
			if(response.data.id == 1) {
				var dataSepDic21 = response.data.data.SepDic21;
				var dataSepDic20 = response.data.data.SepDic20;
				var total_ingresos_21 = 0;
				var total_hombres_21 = 0;
				var total_mujeres_21 = 0;
				var total_ingresos_20 = 0;
				var total_hombres_20 = 0;
				var total_mujeres_20 = 0;

				for (var i = 0; i < dataSepDic21.length; i++) {
					var sepDic21 = dataSepDic21[i];
					total_ingresos_21 =  (parseInt(sepDic21.total_ingresos_alumnos) + total_ingresos_21);
					total_hombres_21 =  (parseInt(sepDic21.cantidad_ingresos_hombres) + total_hombres_21);
					total_mujeres_21 =  (parseInt(sepDic21.cantidad_ingresos_mujeres) + total_mujeres_21);
				}

				for (var i = 0; i < dataSepDic20.length; i++) {
					var sepDic20 = dataSepDic20[i];
					total_ingresos_20 =  (parseInt(sepDic20.total_ingresos_alumnos) + total_ingresos_20);
					total_hombres_20 =  (parseInt(sepDic20.cantidad_ingresos_hombres) + total_hombres_20);
					total_mujeres_20 =  (parseInt(sepDic20.cantidad_ingresos_mujeres) + total_mujeres_20);
				}

				setTotalIngresosMatriculaNI21(total_ingresos_21);
				setTotalHombresNI21(total_hombres_21);
				setTotalMujeresNI21(total_mujeres_21);
				setTotalIngresosMatriculaNI20(total_ingresos_20);
				setTotalHombresNI20(total_hombres_20);
				setTotalMujeresNI20(total_mujeres_20);
			}
		})
		.catch(function (error) {
			console.log(error);
			toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
			setShowLoadingGif(false);
		});
	}

	const getPorcentajeDesercionSepDic = () => {
		setShowLoadingGif(true);
		const axios = require('axios');
		axios.post(environment.apiHome + 'get/porcentaje/desercion/sep/dic', data)
		.then(function (response) {
			console.log("response desercion: ",response);
			setShowLoadingGif(false);
			if(response.data.id == 1) {
				var dataSepDic21 = response.data.data.SepDic21;
				var dataSepDic20 = response.data.data.SepDic20;
				var total_ingresos_21 = 0;
				var total_desertores_21 = 0;
				var total_ingresos_20 = 0;
				var total_desertores_20 = 0;

				for (var i = 0; i < dataSepDic21.length; i++) {
					var sepDic21 = dataSepDic21[i];
					total_ingresos_21 =  (parseInt(sepDic21.total_ingresos_alumnos) + total_ingresos_21);
					total_desertores_21 =  (parseInt(sepDic21.total_desertores) + total_desertores_21);
				}

				for (var i = 0; i < dataSepDic20.length; i++) {
					var sepDic20 = dataSepDic20[i];
					total_ingresos_20 =  (parseInt(sepDic20.total_ingresos_alumnos) + total_ingresos_20);
					total_desertores_20 =  (parseInt(sepDic20.total_desertores) + total_desertores_20);
				}

				//console.log("total_ingresos_21: ",total_ingresos_21);
				//console.log("total_desertores_21: ",total_desertores_21);

				if (total_ingresos_21 > 0 && total_desertores_21 > 0) {
					var porcentajeDe21 = (total_desertores_21 / total_ingresos_21) * 100;
					//console.log("porcentajeDe21: ",porcentajeDe21);
					setPorcentajeDesercion21(formatDecimals(porcentajeDe21));
				}

				if (total_ingresos_20 > 0 && total_desertores_20 > 0) {
					var porcentajeDe20 = (total_desertores_20 / total_ingresos_20) * 100;
					//console.log("porcentajeDe20: ",porcentajeDe20);
					setPorcentajeDesercion20(formatDecimals(porcentajeDe20));
				}

			}
		})
		.catch(function (error) {
			console.log(error);
			toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
			setShowLoadingGif(false);
		});
	}

	const getAprovechamientoSepDic = () => {
		setShowLoadingGif(true);
		const axios = require('axios');
		axios.post(environment.apiHome + 'get/aprovechamiento/sep/dic', data)
		.then(function (response) {
			console.log("response aprovechamiento: ",response);
			setShowLoadingGif(false);
			if(response.data.id == 1) {
				var dataSepDic21 = response.data.data.SepDic21;
				var dataSepDic20 = response.data.data.SepDic20;

				var promedio_21 = 0;
				var promedio_20 = 0;

				for (var i = 0; i < dataSepDic21.length; i++) {
					var sepDic21 = dataSepDic21[i];
					promedio_21 =  (parseFloat(sepDic21.promedio_total) + promedio_21);
				}

				for (var i = 0; i < dataSepDic20.length; i++) {
					var sepDic20 = dataSepDic20[i];
					promedio_20 =  (parseFloat(sepDic20.promedio_total) + promedio_20);
				}

				console.log("promedio_21: ",promedio_21);
				console.log("promedio_20: ",promedio_20);

				if (promedio_21 > 0) {
					var p21 = (promedio_21 / dataSepDic21.length);
					console.log("p21: ",p21);
					setPromedio21(formatDecimals(p21));
				}

				if (promedio_21 > 0) {
					var p20 = (promedio_20 / dataSepDic20.length);
					setPromedio20(p20);
				}

			}
		})
		.catch(function (error) {
			console.log(error);
			toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
			setShowLoadingGif(false);
		});
	}


	const trafficReprobacion = {
	  chartLabels: ['Sep-Dic 21', 'Sep-Dic 20'],
	  chartDatasets: [
		 {
			label: 'Porcentaje de reprobacion',
			backgroundColor: ChartConfig.color.primary,
			borderColor: ChartConfig.color.primary,
			borderWidth: 1,
			hoverBackgroundColor: ChartConfig.color.primary,
			hoverBorderColor: ChartConfig.color.primary,
			data: [porcentajeReprobacion21, porcentajeReprobacion20]
		}
	  ]
	};
	var indicadorReprobacion = [];
	indicadorReprobacion.push(
		<StackedBarChart
		   labels={trafficReprobacion.chartLabels}
		   datasets={trafficReprobacion.chartDatasets}
		/>
	);

	const trafficMatricula = {
	  chartLabels: ['Sep-Dic 21', 'Sep-Dic 20'],
	  chartDatasets: [
		{
			label: 'Mujeres',
			backgroundColor: ChartConfig.color.danger,
			borderColor: ChartConfig.color.danger,
			borderWidth: 2,
			hoverBackgroundColor: ChartConfig.color.danger,
			hoverBorderColor: ChartConfig.color.danger,
			data: [totalMujeres21, totalMujeres20]
		},
		{
			label: 'Hombres',
			backgroundColor: ChartConfig.color.primary,
			borderColor: ChartConfig.color.primary,
			borderWidth: 2,
			hoverBackgroundColor: ChartConfig.color.primary,
			hoverBorderColor: ChartConfig.color.primary,
			data: [totalHombres21, totalHombres20]
		},
		{
			label: 'Matrícula inicial',
			backgroundColor: ChartConfig.color.success,
			borderColor: ChartConfig.color.success,
			borderWidth: 2,
			hoverBackgroundColor: ChartConfig.color.success,
			hoverBorderColor: ChartConfig.color.success,
			data: [totalIngresosMatricula21, totalIngresosMatricula20]
		}
	  ]
	};
	var indicadorMatricula = [];
	indicadorMatricula.push(
		<StackedBarChart
		   labels={trafficMatricula.chartLabels}
		   datasets={trafficMatricula.chartDatasets}
		/>
	);

	const trafficMatriculaNuevoIngreso = {
	  chartLabels: ['Sep-Dic 21', 'Sep-Dic 20'],
	  chartDatasets: [
		{
			label: 'Mujeres',
			backgroundColor: ChartConfig.color.greyLighten,
			borderColor: ChartConfig.color.greyLighten,
			borderWidth: 2,
			hoverBackgroundColor: ChartConfig.color.greyLighten,
			hoverBorderColor: ChartConfig.color.greyLighten,
			data: [totalMujeresNI21, totalMujeresNI20]
		},
		{
			label: 'Hombres',
			backgroundColor: ChartConfig.color.warning,
			borderColor: ChartConfig.color.warning,
			borderWidth: 2,
			hoverBackgroundColor: ChartConfig.color.warning,
			hoverBorderColor: ChartConfig.color.warning,
			data: [totalHombresNI21, totalHombresNI20]
		},
		{
			label: 'Matrícula inicial',
			backgroundColor: ChartConfig.color.purple,
			borderColor: ChartConfig.color.purple,
			borderWidth: 2,
			hoverBackgroundColor: ChartConfig.color.purple,
			hoverBorderColor: ChartConfig.color.purple,
			data: [totalIngresosMatriculaNI21, totalIngresosMatriculaNI20]
		}
	  ]
	};
	var indicadorMatriculaNuevoIngreso = [];
	indicadorMatriculaNuevoIngreso.push(
		<StackedBarChart
		   labels={trafficMatriculaNuevoIngreso.chartLabels}
		   datasets={trafficMatriculaNuevoIngreso.chartDatasets}
		/>
	);

	const trafficDesercion = {
	  chartLabels: ['Sep-Dic 21', 'Sep-Dic 20'],
	  chartDatasets: [
		 {
			label: 'Porcentaje de deserción',
			backgroundColor: ChartConfig.color.default,
			borderColor: ChartConfig.color.default,
			borderWidth: 5,
			hoverBackgroundColor: ChartConfig.color.default,
			hoverBorderColor: ChartConfig.color.default,
			data: [porcentajeDesercion21, porcentajeDesercion20]
		}
	  ]
	};
	var indicadorDesercion = [];
	indicadorDesercion.push(
		<StackedBarChart
		   labels={trafficDesercion.chartLabels}
		   datasets={trafficDesercion.chartDatasets}
		/>
	);

	const trafficAprovechamiento = {
	  chartLabels: ['Sep-Dic 21', 'Sep-Dic 20'],
	  chartDatasets: [
		 {
			label: 'Promedio general por cuatrimestre',
			backgroundColor: ChartConfig.color.warning,
			borderColor: ChartConfig.color.warning,
			borderWidth: 5,
			hoverBackgroundColor: ChartConfig.color.warning,
			hoverBorderColor: ChartConfig.color.warning,
			data: [promedio21, promedio20]
		}
	  ]
	};
	var indicadorAprovechamiento = [];
	indicadorAprovechamiento.push(
		<StackedBarChart
		   labels={trafficAprovechamiento.chartLabels}
		   datasets={trafficAprovechamiento.chartDatasets}
		/>
	);



	var indicadorOne = [];
	return (
		<div className="map-wrapper"><ToastContainer />
			<PageTitleBar title={<IntlMessages id="sidebar.dashboard" />} match={props.match} />

			<div className="position_loading">
				<GifLoader
					loading={showLoadingGif}
					imageSrc={environment.appHome + 'assets/images/gif/loading.gif'}
					imageStyle={environment.styleLoading}
					overlayBackground="rgba(0,0,0,0.5)"
				/>
			</div>
			<div className="row">
				<div className="col-sm-12 col-md-12 col-xl-6">
					<RctCollapsibleCard  heading={"Datos de matrícula de los cuatrimestres SEP-DIC 21 y SEP-DIC 20"}  >
						{indicadorMatricula}
					</RctCollapsibleCard>
				</div>
				<div className="col-sm-12 col-md-12 col-xl-6">
					<RctCollapsibleCard  heading={"Datos de matrícula de nuevo ingreso de SEP-DIC 21 y SEP-DIC 20"}  >
						{indicadorMatriculaNuevoIngreso}
					</RctCollapsibleCard>
				</div>
				<div className="col-sm-12 col-md-12 col-xl-4">
					<RctCollapsibleCard  heading={"Gráfica de porcentaje de reprobación de los cuatrimestres SEP-DIC 21 y SEP-DIC 20"}  >
						{indicadorReprobacion}
					</RctCollapsibleCard>
				</div>
				<div className="col-sm-12 col-md-12 col-xl-4">
					<RctCollapsibleCard  heading={"Gráfica de porcentaje de deserción de los cuatrimestres SEP-DIC 21 y SEP-DIC 20"}  >
						{indicadorDesercion}
					</RctCollapsibleCard>
				</div>
				<div className="col-sm-12 col-md-12 col-xl-4">
					<RctCollapsibleCard  heading={"Gráfica de promedio general de los cuatrimestres SEP-DIC 21 y SEP-DIC 20"}  >
						{indicadorAprovechamiento}
					</RctCollapsibleCard>
				</div>
			</div>

			{/*<div className="row">


				<div className="col-sm-12 col-md-12 col-xl-6">
					<RctCard>
				        <RctCardContent>
				            <div className="clearfix">
				                <div className="float-left">
				                    <h3 className="mb-15 fw-semi-bold"><IntlMessages id="widgets.visitors" /></h3>
				                    <div className="d-flex">
				                        <div className="mr-50">
				                            <span className="fs-14 d-block"><IntlMessages id="widgets.weekly" /></span>
				                            <CountUp
				                                separator=","
				                                className="counter-point"
				                                start={0}
				                                end={visitorsData.weekly}
				                                duration={5}
				                                useEasing={true}
				                            />
				                        </div>
				                        <div className="">
				                            <span className="fs-14 d-block"><IntlMessages id="widgets.monthly" /></span>
				                            <CountUp separator="," className="counter-point" start={0} end={visitorsData.monthly} duration={5} useEasing={true} />
				                        </div>
				                    </div>
				                </div>
				                <div className="float-right hidden-md-down">
				                    <div className="featured-section-icon">
				                        <i className="zmdi zmdi-globe-alt"></i>
				                    </div>
				                </div>
				            </div>
				        </RctCardContent>
				        <TinyAreaChart
				            label="Visitors"
				            chartdata={visitorsData.chartData.data}
				            labels={visitorsData.chartData.labels}
				            backgroundColor={hexToRgbA(ChartConfig.color.primary, 0.1)}
				            borderColor={hexToRgbA(ChartConfig.color.primary, 3)}
				            lineTension="0"
				            height={70}
				            gradient
				            hideDots
				        />
				    </RctCard >
				</div>


			</div>*/}

	 	</div>
    )
}
