import appLocaleData from 'react-intl/locale-data/es';
import enMessages from '../locales/es_ES';
console.log("enMessages: ",enMessages);
const EnLang = {
    messages: {
        ...enMessages
    },
    locale: 'es-ES',
    data: appLocaleData
};
export default EnLang;
