/**
 * Pages Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// async components
import {
    AsyncListAprovechamientoComponent,
    AsyncAddAprovechamientoComponent
} from 'Components/AsyncComponent/AsyncComponent';


const Aprovechamiento = ({ match }) => (
    <div className="content-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={AsyncListAprovechamientoComponent} />
            <Route path={`${match.url}/add`} component={AsyncAddAprovechamientoComponent} />
        </Switch>
    </div>
);

export default Aprovechamiento;
