/**
 * App.js Layout Start Here
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
// rct theme provider
import RctThemeProvider from './RctThemeProvider';
//Main App
import RctDefaultLayout from './DefaultLayout';

// async components
import { AsyncLoginComponent } from 'Components/AsyncComponent/AsyncComponent';
// env SME
import { AppEnv } from 'App/AppConfig';

function App(props) {
    const environment = AppEnv.confCurrent;
    const contextPath = environment.contextPath;
    const isLogged = (localStorage.getItem("ut_email") != null ? true: false);
    const { location } = props;

    console.log("environment in APP: " ,environment);
    console.log("contextPath: ",contextPath);

    if (location.pathname === '/') {
        return (<Redirect to={'/login'} />);
    }

    return (
        <RctThemeProvider>
            <NotificationContainer />
            <Route path={contextPath + "/app"} component={RctDefaultLayout} />
            <Route path={contextPath + "/login"} component={AsyncLoginComponent} />
        </RctThemeProvider>
    );
}

export default App;
