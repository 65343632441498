var confCurrent = {
    appContext: "",
    contextPath: "",
    initialContext: "",
    appHome: "https://indicadores.tecnologias-utsemmorelos.com.mx/",
    apiHome: "https://indicadores.tecnologias-utsemmorelos.com.mx/",

    // appHome: "http://indicadores.utsem-morelos.edu.mx/",//"http://localhost:3000/", // dev: http://192.168.1.80/AppIndicadoresBack/
    // apiHome: "http://indicadores.utsem-morelos.edu.mx/", //"http://indicadores.utsem-morelos.edu.mx/"

    // appHome: "http://localhost/AppIndicadoresBack/", 
    // apiHome: "http://localhost/AppIndicadoresBack/",

    lectura: localStorage.getItem('p_lectura'),
    todos: localStorage.getItem('p_todos'),
    agregar: localStorage.getItem('p_agregar'),
    editar: localStorage.getItem('p_editar'),
    borrar: localStorage.getItem('p_borrar'),

    optionsDataTable: {
       filterType: 'dropdown',
       responsive: 'stacked',
       selectableRows: 'none',
       selectableRowsOnClick: false,
       selectableRowsHideCheckboxes: true,
       filter: true,
       download: false,
       print: false,
       sort: true,
       textLabels: {
         body: { noMatch: "Lo sentimos, no se encontrarón registros", toolTip: "Sort", columnHeaderTooltip: column => `Sort for ${column.label}` },
         pagination: { next: "Página siguiente:", previous: "Página anterior:", rowsPerPage: "Filas por página:",displayRows: "de", },
         toolbar: { search: "Buscar", downloadCsv: "Descargar CSV", print: "Imprimir", viewColumns: "Ver Columnas", filterTable: "Filtro de la Tabla", },
         filter: {  all: "Todo", title: "Filtros", reset: "Reset", },
         viewColumns: {  title: "Mostrar columnas", titleAria: "Mostrar/Ocultar Columnas de la Tabla", },
         selectedRows: { text: "registro(s) selecccionado", delete: "Borrar", deleteAria: "Borrar Registro Seleccionado",},
       }
   },
    styleLoading: {
        width:"77px",
        margin:"20%",
        height:"77px"
    }
}

export function getConfigCurrent() {
    return confCurrent;
}
