/**
 * Pages Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// async components
import {
    AsyncListDesercionComponent,
    AsyncAddDesercionComponent
} from 'Components/AsyncComponent/AsyncComponent';


const Desercion = ({ match }) => (
    <div className="content-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={AsyncListDesercionComponent} />
            <Route path={`${match.url}/add`} component={AsyncAddDesercionComponent} />
        </Switch>
    </div>
);

export default Desercion;
