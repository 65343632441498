let dic = {
   "sidebar.app": "App",
   "sidebar.news": "News",
   "sidebar.horizontal": "Horizontal",
   "sidebar.horizontalMenu": "Horizontal Menu",
   "sidebar.general": "General",
   "sidebar.component": "Component",
   "sidebar.features": "Features",
   "sidebar.applications": "Applications",
   "sidebar.dashboard": "Dashboard",
   "sidebar.dashboard1": "Dashboard 1",
   "sidebar.dashboard2": "Dashboard 2",
   "sidebar.dashboard3": "Dashboard 3",
   "sidebar.modules": "Modules",
   "sidebar.agency": "Agency",
   "sidebar.pages": "Pages",
   "sidebar.gallery": "Gallery",
   "sidebar.pricing": "Pricing",
   "sidebar.terms&Conditions": "Terms & Conditions",
   "sidebar.feedback": "Feedback",
   "sidebar.report": "Report",
   "sidebar.faq(s)": "Faq(s)",
   "sidebar.advancedComponent": "Advance Component",
   "sidebar.blank": "Blank",
   "sidebar.session": "Session",
   "sidebar.login": "Login",
   "sidebar.register": "Register",
   "sidebar.lockScreen": "Lock Screen",
   "sidebar.forgotPassword": "Forgot Password",
   "sidebar.404": "404",
   "sidebar.500": "500",
   "sidebar.uiComponents": "UI Components",
   "sidebar.alerts": "Alerts",
   "sidebar.appBar": "App Bar",
   "sidebar.avatars": "Avatars",
   "sidebar.buttons": "Buttons",
   "sidebar.bottomNavigations": "Bottom Navigations",
   "sidebar.badges": "Badges",
   "sidebar.cards": "Cards",
   "sidebar.cardsMasonry": "Cards Masonry",
   "sidebar.chip": "Chip",
   "sidebar.dialog": "Dialog",
   "sidebar.dividers": "Dividers",
   "sidebar.drawers": "Drawers",
   "sidebar.popover": "Popover",
   "sidebar.expansionPanel": "Expansion Panel",
   "sidebar.gridList": "Grid List",
   "sidebar.list": "List",
   "sidebar.menu": "Menu",
   "sidebar.popoverAndToolTip": "Pop Over & ToolTip",
   "sidebar.progress": "Progress",
   "sidebar.snackbar": "Snackbar",
   "sidebar.selectionControls": "Selection Controls",
   "sidebar.advanceUiComponents": "Advance UI Components",
   "sidebar.dateAndTimePicker": "Date & Time Picker",
   "sidebar.tabs": "Tabs",
   "sidebar.stepper": "Stepper",
   "sidebar.notification": "Notification",
   "sidebar.sweetAlert": "Sweet Alert",
   "sidebar.autoComplete": "Auto Complete",
   "sidebar.aboutUs": "About Us",
   "sidebar.widgets": "Widgets",
   "sidebar.forms": "Forms",
   "sidebar.formElements": "Form Elements",
   "sidebar.textField": "Text Field",
   "sidebar.selectList": "Select List",
   "sidebar.charts": "Charts",
   "sidebar.reCharts": "Re Charts",
   "sidebar.reactChartjs2": "React Chartjs 2",
   "sidebar.icons": "Icons",
   "sidebar.themifyIcons": "Themify Icons",
   "sidebar.simpleLineIcons": "Simple Line Icons",
   "sidebar.materialIcons": "Material Icons",
   "sidebar.fontAwesome": "Font Awesome",
   "sidebar.tables": "Tables",
   "sidebar.basic": "Basic",
   "sidebar.dataTable": "Data Table",
   "sidebar.responsive": "Responsive",
   "sidebar.reactTable": "React Table",
   "sidebar.maps": "Maps",
   "sidebar.googleMaps": "Google Maps",
   "sidebar.leafletMaps": "Leaflet Maps",
   "sidebar.inbox": "Inbox",
   "sidebar.users": "Users",
   "sidebar.userProfile1": "User Profile 1",
   "sidebar.userProfile2": "User Profile 2",
   "sidebar.userManagement": "User Management",
   "sidebar.userProfile": "User Profile",
   "sidebar.userList": "User List",
   "sidebar.calendar": "Calendar",
   "sidebar.cultures": "Cultures",
   "sidebar.dnd": "Dnd",
   "sidebar.selectable": "Selectable",
   "sidebar.customRendering": "Custom Rendering",
   "sidebar.chat": "Chat",
   "sidebar.toDo": "ToDo",
   "sidebar.editor": "Editor",
   "sidebar.wysiwygEditor": "WYSIWYG Editor",
   "sidebar.quillEditor": "Quill Editor",
   "sidebar.reactAce": "React Ace",
   "sidebar.dragAndDrop": "Drag And Drop",
   "sidebar.reactDragula": "React Dragula",
   "sidebar.reactDnd": "React Dnd",
   "sidebar.blogManagement": "Blog Management",
   "sidebar.ecommerce": "Ecommerce",
   "sidebar.shopList": "Shop List",
   "sidebar.shopGrid": "Shop Grid",
   "sidebar.invoice": "Invoice",
   "sidebar.multilevel": "Multilevel",
   "sidebar.sublevel": "Sublevel",
   "sidebar.crypto": "Crypto",
   "sidebar.marketCap": "Market cap",
   "sidebar.wallet": "wallet",
   "sidebar.trade": "trade",
   "sidebar.crm": "CRM",
   "widgets.totalEarns": "Total Earns",
   "widgets.emailsStatistics": "Emails Statistics",
   "widgets.totalRevenue": "Total Revenue",
   "widgets.onlineVistors": "Online Vistors",
   "widgets.trafficSources": "Traffic Sources",
   "widgets.RecentOrders": "Recent Orders",
   "widgets.topSellings": "Top Sellings",
   "widgets.productReports": "Product Reports",
   "widgets.productStats": "Product Stats",
   "widgets.ComposeEmail": "Compose Email",
   "widgets.employeePayroll": "Employee Payroll",
   "widgets.visitors": "Visitors",
   "widgets.orders": "Orders",
   "widgets.orderStatus": "Order Status",
   "widgets.totalSales": "Total Sales",
   "widgets.netProfit": "Net Profit",
   "widgets.overallTrafficStatus": "Overall Traffic Status",
   "widgets.tax": "Tax",
   "widgets.expenses": "Expenses",
   "widgets.currentTime": "Current Time",
   "widgets.currentDate": "Current Date",
   "widgets.todayOrders": "Today Orders",
   "widgets.toDoList": "To Do Lists",
   "widgets.discoverPeople": "Discover People",
   "widgets.commments": "Comments",
   "widgets.newCustomers": "New Customers",
   "widgets.recentNotifications": "Recent Notifications",
   "widgets.appNotifications": "App Notifications",
   "widgets.ratings": "Ratings",
   "widgets.newEmails": "New Emails",
   "widgets.siteVisitors": "Site Visitors",
   "widgets.socialCompanines": "Social Companines",
   "widgets.recentActivities": "Recent Activities",
   "widgets.recentOrders": "Recent Orders",
   "widgets.gallery": "Gallery",
   "widgets.pricing": "Pricing",
   "widgets.enterpriseEdition": "Enterprise Edition",
   "widgets.personalEdition": "Personal Edition",
   "widgets.teamEdition": "Team Edition",
   "widgets.standard": "Standard",
   "widgets.advanced": "Advanced",
   "widgets.master": "Master",
   "widgets.Mega": "Mega",
   "widgets.logIn": "Log In",
   "widgets.signUp": "Sign Up",
   "widgets.lockScreen": "Lock Screen",
   "widgets.alertsWithLink": "Alerts With Link",
   "widgets.additionalContent": "Additional Content",
   "widgets.alertDismiss": "Alert Dismiss",
   "widgets.uncontrolledDisableAlerts": "Uncontrolled Disable Alerts",
   "widgets.contexualAlerts": "Contexual Alerts",
   "widgets.alertsWithIcons": "Alerts With Icons",
   "widgets.Simple App Bars": "Simple App Bars",
   "widgets.appBarsWithButtons": "App Bars With Buttons",
   "widgets.imageAvatars": "Image Avatars",
   "widgets.lettersAvatars": "Letters Avatars",
   "widgets.iconsAvatars": "Icons Avatars",
   "widgets.flatButtons": "Flat Buttons",
   "widgets.raisedButton": "Raised Button",
   "widgets.buttonWithIconAndLabel": "Button With Icon And Label",
   "widgets.floatingActionButtons": "Floating Action Buttons",
   "widgets.iconButton": "Icon Button",
   "widgets.socialMediaButton": "Social Media Button",
   "widgets.reactButton": "React Button",
   "widgets.buttonOutline": "Button Outline",
   "widgets.buttonSize": "Button Size",
   "widgets.buttonState": "Button State",
   "widgets.buttonNavigationWithNoLabel": "button Navigation With No Label",
   "widgets.buttonNavigation": "Button Navigation",
   "widgets.iconNavigation": "Icon Navigation",
   "widgets.badgeWithHeadings": "Badge With Headings",
   "widgets.contexualVariations": "Contexual Variations",
   "widgets.badgeLinks": "Badge Links",
   "widgets.materialBadge": "Material Badge",
   "widgets.simpleCards": "Simple Cards",
   "widgets.backgroundVarient": "Background Varient",
   "widgets.cardOutline": "Card Outline",
   "widgets.overlayCard": "Overlay Card",
   "widgets.cardGroup": "Card Group",
   "widgets.cardTitle": "Card Title",
   "widgets.speacialTitleTreatment": "Speacial Title Treatment",
   "widgets.chipWithClickEvent": "Chip With Click Event",
   "widgets.chipArray": "Chip Array",
   "widgets.dialogs": "Dialogs",
   "widgets.listDividers": "List Dividers",
   "widgets.insetDividers": "Inset Dividers",
   "widgets.temporaryDrawers": "Temporary Drawers",
   "widgets.permanentDrawers": "Permanent Drawers",
   "widgets.simpleExpansionPanel": "Simple Expansion Panel",
   "widgets.controlledAccordion": "Controlled Accordion",
   "widgets.secondaryHeadingAndColumns": "Secondary Heading And Columns",
   "widgets.imageOnlyGridLists": "Image Only Grid Lists",
   "widgets.advancedGridLists": "Advanced Grid Lists",
   "widgets.singleLineGridLists": "Single Line Grid Lists",
   "widgets.simpleLists": "Simple Lists",
   "widgets.folderLists": "Folder Lists",
   "widgets.listItemWithImage": "List Item With Image",
   "widgets.switchLists": "Switch Lists",
   "widgets.insetLists": "Inset Lists",
   "widgets.nestedLists": "Nested Lists",
   "widgets.checkboxListControl": "Checkbox List Control",
   "widgets.pinedSubHeader": "Pined Sub Header",
   "widgets.InteractiveLists": "Interactive Lists",
   "widgets.simpleMenus": "Simple Menus",
   "widgets.selectedMenu": "Selected Menu",
   "widgets.maxHeightMenu": "Max Height Menu",
   "widgets.changeTransition": "Change Transition",
   "widgets.paper": "Paper",
   "widgets.anchorPlayGround": "Anchor Play Ground",
   "widgets.tooltip": "ToolTip",
   "widgets.positionedToolTips": "Positioned Snackbar",
   "widgets.circularProgressBottomStart": "Circular Progress Bottom Start",
   "widgets.interactiveIntegration": "Interactive Integration",
   "widgets.determinate": "Determinate",
   "widgets.linearProgressLineBar": "Linear Progress Line Bar",
   "widgets.indeterminate": "Indeterminate",
   "widgets.buffer": "Buffer",
   "widgets.query": "Query",
   "widgets.transitionControlDirection": "Transition Control Direction",
   "widgets.simpleSnackbar": "Simple Snackbar",
   "widgets.positionedSnackbar": "Positioned Snackbar",
   "widgets.contexualColoredSnackbars": "Contexual Colored Snackbars",
   "widgets.simpleCheckbox": "Simple Checkbox",
   "widgets.interminateSelection": "Interminate Selection",
   "widgets.disabledCheckbox": "Disabled Checkbox",
   "widgets.customColorCheckbox": "Custom Color Checkbox",
   "widgets.VerticalStyleCheckbox": "Vertical Style Checkbox",
   "widgets.horizontalStyleCheckbox": "Horizontal Style Checkbox",
   "widgets.radioButtons": "Radio Buttons",
   "widgets.disabledRadio": "Disabled Radio",
   "widgets.withError": "With Error",
   "widgets.switches": "Swiches",
   "widgets.dateAndTimePicker": "Date And Time Picker",
   "widgets.defaultPicker": "Default Picker",
   "widgets.timePicker": "Time Picker",
   "widgets.weekPicker": "Week Picker",
   "widgets.defaultDatePicker": "Default Date Picker",
   "widgets.customPicker": "Custom Picker",
   "widgets.tabs": "Tabs",
   "widgets.fixedTabs": "Fixed Tabs",
   "widgets.basicTab": "Basic Tab",
   "widgets.wrappedLabels": "Wrapped Labels",
   "widgets.centeredLabels": "Centered Labels",
   "widgets.forcedScrolledButtons": "Forced Scrolled Buttons",
   "widgets.iconsTabs": "Icons Tabs",
   "widgets.withDisableTabs": "With Disable Tabs",
   "widgets.iconWithLabel": "Icon With Label",
   "widgets.stepper": "Stepper",
   "widgets.horizontalLinear": "Horizontal Linear",
   "widgets.horizontalNonLinear": "Horizontal Non Linear",
   "widgets.horizontalLinerAlternativeLabel": "Horizontal Liner Alternative Label",
   "widgets.horizontalNonLinerAlternativeLabel": "Horizontal Non Liner Alternative Label",
   "widgets.verticalStepper": "Vertical Stepper",
   "widgets.descriptionAlert": "Description Alert",
   "widgets.customIconAlert": "Custom Icon Alert",
   "widgets.withHtmlAlert": "With Html Alert",
   "widgets.promptAlert": "Prompt Alert",
   "widgets.passwordPromptAlert": "Password Prompt Alert",
   "widgets.customStyleAlert": "Custom Style Alert",
   "widgets.autoComplete": "Auto Complete",
   "widgets.reactSelect": "React Select",
   "widgets.downshiftAutoComplete": "Downshift Auto Complete",
   "widgets.reactAutoSuggests": "React Auto Suggests",
   "widgets.aboutUs": "About Us",
   "widgets.ourVission": "Our Vission",
   "widgets.ourMissions": "Our Missions",
   "widgets.ourMotivation": "Our Motivation",
   "widgets.defualtReactForm": "Defualt React Form",
   "widgets.url": "Url",
   "widgets.textArea": "Text Area",
   "widgets.file": "File",
   "widgets.formGrid": "Form Grid",
   "widgets.inlineForm": "Inline Form",
   "widgets.inputSizing": "Input Sizing",
   "widgets.inputGridSizing": "Input Grid Sizing",
   "widgets.hiddenLabels": "Hidden Labels",
   "widgets.formValidation": "Form Validation",
   "widgets.number": "Number",
   "widgets.date": "Date",
   "widgets.time": "Time",
   "widgets.color": "Color",
   "widgets.search": "Search",
   "widgets.selectMultiple": "Select Multiple",
   "widgets.inputWithSuccess": "Input With Success",
   "widgets.inputWithDanger": "Input With Danger",
   "widgets.simpleTextField": "Simple Text Field",
   "widgets.componet": "Components",
   "widgets.layouts": "Layouts",
   "widgets.inputAdorements": "Input Adorements",
   "widgets.formattedInputs": "Formatted Inputs",
   "widgets.simpleSelect": "Simple Select",
   "widgets.nativeSelect": "Native Select",
   "widgets.MutltiSelectList": "Mutlti Select List",
   "widgets.lineChart": "Line Chart",
   "widgets.barChart": "Bar Chart",
   "widgets.stackedBarChart": "Stacked Bar Chart",
   "widgets.lineBarAreaChart": "Line Bar Area Chart",
   "widgets.areaChart": "Area Chart",
   "widgets.stackedAreaChart": "Stacked Area Chart",
   "widgets.verticalChart": "Vertical Chart",
   "widgets.radarChart": "Radar Chart",
   "widgets.doughnut": "Doughnut",
   "widgets.polarChart": "Polar Chart",
   "widgets.pieChart": "Pie Chart",
   "widgets.bubbleChart": "Bubble Chart",
   "widgets.horizontalBar": "Horizontal Bar",
   "widgets.basicTable": "Basic Table",
   "widgets.contexualColoredTable": "Contexual Colored Table",
   "widgets.dataTable": "Data Table",
   "widgets.employeeList": "Employee List",
   "widgets.responsiveTable": "Responsive Table",
   "widgets.responsiveFlipTable": "Responsive Flip Table",
   "widgets.reactGridControlledStateMode": "React Grid Controlled State Mode",
   "widgets.productsReports": "Products Reports",
   "widgets.taskList": "Task List",
   "widgets.basicCalender": "Basic Calender",
   "widgets.culturesCalender": "Cultures Calender",
   "widgets.dragAndDropCalender": "Drag And Drop Calender",
   "widgets.selectableCalender": "Selectable Calender",
   "widgets.customRendering": "Custom Rendering",
   "widgets.customCalender": "Custom Calender",
   "widgets.searchMailList": "Search Mail List",
   "components.buyNow": "Buy Now",
   "compenets.choose": "Choose",
   "compenets.username": "Username",
   "compenets.passwords": "Passwords",
   "widgets.forgetPassword": "Forget Password",
   "compenets.signIn": "Sign In",
   "compenets.dontHaveAccountSignUp": "Dont Have Account SignUp",
   "compenets.enterUserName": "Enter User Name",
   "compenets.enterEmailAddress": "Enter Email Address",
   "compenets.confirmPasswords": "Confirm Passwords",
   "components.alreadyHavingAccountSignIn": "Already Having Account Sign In",
   "components.enterYourPassword": "Enter Your Password",
   "components.unlock": "Unlock",
   "components.enterPasswords": "Enter Passwords",
   "components.resestPassword": "Resest Password",
   "components.pageNotfound": "Page not Found",
   "components.goToHomePage": "Go To Home Page",
   "components.sorryServerGoesWrong": "Sorry Server Goes Wrong",
   "components.persistentDrawer": "Persistent Drawer",
   "components.withHtml": "With Html",
   "components.prompt": "Prompt",
   "components.withDescription": "With Description",
   "components.success": "Success",
   "components.passwordPrompt": "Password Prompt",
   "components.warning": "Warning",
   "components.customIcon": "Custom Icon",
   "components.customStyle": "Custom Style",
   "components.basic": "Basic",
   "components.submit": "Submit",
   "components.compose": "Compose",
   "components.sendMessage": "Send Message",
   "components.addNewTasks": "Add New Tasks",
   "components.addToCart": "Add To Cart",
   "components.payNow": "Pay Now",
   "components.print": "Print",
   "components.cart": "Cart",
   "components.viewCart": "View Cart",
   "components.checkout": "Checkout",
   "widgets.QuickLinks": "Quick Links",
   "widgets.upgrade": "upgrade",
   "widgets.app": "App",
   "widgets.addNew": "Add New",
   "widgets.orderDate": "Order Date",
   "widgets.status": "Status",
   "widgets.trackingNumber": "Tracking Number",
   "widgets.action": "Action",
   "widgets.designation": "Designation",
   "widgets.subject": "subject",
   "widgets.send": "Send",
   "widgets.saveAsDrafts": "Save As Drafts",
   "widgets.onlineSources": "Online Sources",
   "widgets.lastMonth": "Last Month",
   "widgets.widgets": "Widgets",
   "widgets.listing": "Listing",
   "widgets.paid": "Paid",
   "widgets.refunded": "Refunded",
   "widgets.done": "Done",
   "widgets.pending": "Pending",
   "widgets.cancelled": "Cancelled",
   "widgets.approve": "Approve",
   "widgets.following": "Following",
   "widgets.follow": "follow",
   "widgets.graphs&Charts": "Graphs & Charts",
   "widgets.open": "Open",
   "widgets.bounced": "Bounced",
   "widgets.spam": "Spam",
   "widgets.unset": "Unset",
   "widgets.bandwidthUse": "Bandwidth Use",
   "widgets.dataUse": "Data Use",
   "widgets.unsubscribe": "Unsubscribe",
   "widgets.profile": "Profile",
   "widgets.messages": "Messages",
   "widgets.support": "Support",
   "widgets.faq(s)": "Faq(s)",
   "widgets.upgradePlains": "Upgrade Plains",
   "widgets.logOut": "Log Out",
   "widgets.mail": "Mail",
   "widgets.adminTheme": "Admin Theme",
   "widgets.wordpressTheme": "Wordpress Theme",
   "widgets.addToCart": "Add To Cart",
   "widgets.plan": "Plan",
   "widgets.basic": "Basic",
   "widgets.pro": "Pro",
   "widgets.startToBasic": "Start To Basic",
   "widgets.upgradeToPro": "Upgrade To Pro",
   "widgets.upgradeToAdvance": "Upgrade To Advance",
   "widgets.comparePlans": "Compare Plans",
   "widgets.free": "Free",
   "widgets.frequentlyAskedQuestions": "Frequently Asked Questions",
   "widgets.searchIdeas": "Search Ideas",
   "widgets.startDate": "Start Date",
   "widgets.endDate": "End Date",
   "widgets.category": "Category",
   "widgets.apply": "Apply",
   "widgets.yesterday": "Yesterday",
   "widgets.totalOrders": "Total Orders",
   "widgets.totalVisitors": "Total Visitors",
   "widgets.typeYourQuestions": "Type Your Questions",
   "widgets.username": "Username",
   "widgets.password": "Password",
   "widgets.signIn": "Sign In",
   "widgets.enterYourPassword": "Enter Your Password",
   "widgets.alreadyHavingAccountLogin": "Already Having Account Login",
   "widgets.composeMail": "Compose Mail",
   "widgets.issue": "Issue",
   "widgets.recentChat": "Recent Chat",
   "widgets.previousChat": "Previous Chat",
   "widgets.all": "All",
   "widgets.filters": "Filters",
   "widgets.deleted": "Deleted",
   "widgets.starred": "Starred",
   "widgets.frontend": "Frontend",
   "widgets.backend": "Backend",
   "widgets.api": "Api",
   "widgets.simpleAppBar": "Simple App Bar",
   "widgets.recents": "Recents",
   "widgets.cardLink": "Card Link",
   "widgets.anotherLink": "Another Link",
   "widgets.cardSubtitle": "card Subtitle",
   "widgets.confirmationDialogs": "Confirmation Dialogs",
   "widgets.deletableChip": "Deletable Chip",
   "widgets.customDeleteIconChip": "Custom Delete Icon Chip",
   "widgets.openAlertDialog": "Open Alert Dialog",
   "widgets.openResponsiveDialog": "Open Responsive Dialog",
   "widgets.openSimpleDialog": "Open Simple Dialog",
   "widgets.openFormDialog": "Open Form Dialog",
   "widgets.follower": "Follower",
   "widgets.important": "Important",
   "widgets.private": "Private",
   "widgets.openLeft": "Open Left",
   "widgets.openRight": "Open Right",
   "widgets.openTop": "Open Top",
   "widgets.openBottom": "Open Bottom",
   "widgets.selectTripDestination": "Select Trip Destination",
   "widgets.pinnedSubheaderList": "Pinned Subheader List",
   "widgets.singleLineItem": "Single Line Item",
   "widgets.optionA": "Option A",
   "widgets.optionB": "Option B",
   "widgets.optionC": "Option C",
   "widgets.optionM": "Option M",
   "widgets.optionN": "Option N",
   "widgets.optionO": "Option O",
   "widgets.customColor": "Custom Color",
   "widgets.centeredTabs": "Centered Tabs",
   "widgets.multipleTabs": "Multiple Tabs",
   "widgets.preventScrolledButtons": "Prevent Scrolled Buttons",
   "widgets.browse": "Browse",
   "widgets.formValidate": "Form Validate",
   "widgets.code": "Code",
   "widgets.company": "Company",
   "widgets.price": "Price",
   "widgets.change": "Change",
   "widgets.high": "High",
   "widgets.low": "Low",
   "widgets.volume": "Volume",
   "widgets.personalDetails": "Personal Details",
   "widgets.occupation": "Occupation",
   "widgets.companyName": "Company Name",
   "widgets.phoneNo": "Phone No",
   "widgets.updateProfile": "Update Profile",
   "widgets.reject": "Reject",
   "widgets.exportToExcel": "Export To Excel",
   "widgets.workWeek": "Work Week",
   "widgets.agenda": "Agenda",
   "widgets.conference": "Conference",
   "widgets.multilevel": "Multilevel",
   "widgets.dailySales": "Daily Sales",
   "widgets.today": "Today",
   "widgets.campaignPerformance": "Campaign Performance",
   "widgets.supportRequest": "Support Request",
   "widgets.usersList": "Users List ",
   "widgets.lastWeek": "Last Week",
   "themeOptions.sidebarOverlay": "Sidebar Overlay",
   "themeOptions.sidebarBackgroundImages": "Sidebar Background Images",
   "themeOptions.appSettings": "App Settings",
   "themeOptions.sidebarImage": "Sidebar Image",
   "themeOptions.miniSidebar": "Mini Sidebar",
   "themeOptions.boxLayout": "Box Layout",
   "themeOptions.rtlLayout": "Rtl Layout",
   "themeOptions.darkMode": "Dark Mode",
   "themeOptions.sidebarLight": "Light",
   "themeOptions.sidebarDark": "Dark",
   "button.cancel": "Cancel",
   "button.add": "Add",
   "button.update": "Update",
   "button.reply": "Reply",
   "button.delete": "Delete",
   "button.yes": "Yes",
   "button.viewAll": "View All",
   "button.like": "Like",
   "button.assignNow": "Assign Now",
   "button.seeInsights": "See Insights",
   "sidebar.dateTimePicker": "Date & Time Picker",
   "components.summary": "Summary",
   "hint.whatAreYouLookingFor": "What are You Looking For",
   "components.yesterday": "Yesterday",
   "components.last7Days": "Last 7 Days",
   "components.last1Month": "Last 1 Month",
   "components.last6Month": "Last 6 Month",
   "components.spaceUsed": "Space Used",
   "components.followers": "Follower",
   "components.trending": "Trending",
   "components.paid": "Paid",
   "components.refunded": "Refunded",
   "components.done": "Done",
   "components.pending": "Pending",
   "components.cancelled": "Cancelled",
   "components.approve": "Approve",
   "components.week": "Week",
   "components.month": "Month",
   "components.year": "Year",
   "components.today": "Today",
   "components.popularity": "Popularity",
   "components.email": "Email",
   "components.drafts": "Drafts",
   "components.sent": "Sent",
   "components.trash": "Trash",
   "components.all": "All",
   "components.do": "Do",
   "components.title": "Title",
   "components.projectName": "Project Name",
   "components.companyName": "Company Name",
   "components.openAlert": "Open Alert",
   "components.slideInAlertDialog": "Slide In Alert Dialog",
   "components.openFullScreenDialog": "Open Full Screen Dialogs",
   "components.basicChip": "Basic Chip",
   "components.clickableChip": "Clickable Chip",
   "components.left": "Left",
   "components.right": "Right",
   "components.expansionPanel1": "Expansion Panel 1",
   "components.expansionPanel2": "Expansion Panel 2",
   "components.generalSetting": "General Setting",
   "components.advancedSettings": "Advanced Settings",
   "components.firstName": "First Name",
   "components.lastName": "Last Name",
   "components.occupation": "Occupation",
   "components.phoneNo": "Phone No",
   "components.address": "Address",
   "components.city": "City",
   "components.state": "State",
   "components.zipCode": "Zip Code",
   "components.social Connection": "Social Connection",
   "widgets.buyMore": "Buy More",
   "widgets.trafficChannel": "Traffic Channel",
   "widgets.stockExchange": "Stock Exchange",
   "widgets.tweets": "Tweets",
   "widgets.ourLocations": "Our Locations",
   "widgets.sales": "Sales",
   "widgets.to": "To",
   "widgets.shipTo": "Ship To",
   "widgets.description": "Description",
   "widgets.unitPrice": "Unit Price",
   "widgets.total": "Total",
   "widgets.note": "Note",
   "widgets.chipWithAvatar": "Chip With Avatar",
   "widgets.chipWithTextAvatar": "Chip With Text Avatar",
   "widgets.chipWithIconAvatar": "Chip With Icon Avatar",
   "widgets.customClickableChip": "Custom Clickable Chip",
   "widgets.outlineChip": "Outline Chip",
   "widgets.disableChip": "Disable Chip",
   "widgets.alertDialog": "Alert Dialog",
   "widgets.animatedSlideDialogs": "Animated Slide Dialogs",
   "widgets.fullScreenDialogs": "Full Screen Dialogs",
   "widgets.formDialogs": "Form Dialogs",
   "widgets.simpleDialogs": "Simple Dialogs",
   "widgets.responsiveFullScreen": "Responsive Full Screen",
   "widgets.primary": "Primary",
   "widgets.social": "Social",
   "widgets.user": "User",
   "widgets.admin": "Admin",
   "widgets.permanentdrawer": "Permanent Drawer",
   "widgets.persistentdrawer": "Persistent Drawer",
   "widgets.swiches": "Swiches",
   "widgets.horizontalLinearAlternativeLabel": "Horizontal Linear Alternative Label",
   "widgets.horizontalNonLinearAlternativeLabel": "Horizontal Non Linear Alternative Label",
   "widgets.notifications": "Notifications",
   "widgets.basicAlert": "Basic Alert",
   "widgets.successAlert": "Success Alert",
   "widgets.warningAlert": "Warning Alert",
   "widgets.reactAutoSuggest": "React Auto Suggest",
   "widgets.components": "Components",
   "widgets.inputAdornments": "Input Adorements",
   "widgets.multiSelectList": "Multi Select List",
   "widgets.contextualColoredTable": "Contexual Colored Table",
   "widgets.updateYourEmailAddress": "Update Your Email Address",
   "widgets.selectADefaultAddress": "Select A Default Address",
   "widgets.activity": "Activity",
   "widgets.basicCalendar": "Basic Calendar",
   "widgets.culturesCalendar": "Cultures Calendar",
   "widgets.dragAndDropCalendar": "Drag And Drop Calendar",
   "widgets.quillEditor": "Quill Editor",
   "widgets.reactDND": "React DND",
   "widgets.dragula": "Dragula",
   "button.acceptTerms": "Accept Terms",
   "button.reject": "Reject",
   "button.addNew": "Add New",
   "button.goToCampaign": "Go To Campaign",
   "button.viewProfile": "View Profile",
   "button.sendMessage": "Send Message",
   "button.saveNow": "Save Now",
   "button.pen": "Pen",
   "button.search": "Search",
   "button.downloadPdfReport": "Download Pdf Report",
   "button.primary": "Primary",
   "button.secondary": "Secondary",
   "button.danger": "Danger",
   "button.info": "Info",
   "button.success": "Success",
   "button.warning": "Warning",
   "button.link": "Link",
   "button.smallButton": "Small Button",
   "button.largeButton": "Large Button",
   "button.blockLevelButton": "Block Level Button",
   "button.primaryButton": "Primary Button",
   "button.button": "Button",
   "button.save": "Save",
   "button.openMenu": "Open Menu",
   "button.openWithFadeTransition": "Open With Fade Transition",
   "button.openPopover": "Open Popover",
   "button.accept": "Accept",
   "button.click": "Click",
   "button.complete": "Complete",
   "button.back": "Back",
   "button.next": "Next",
   "button.completeStep": "Complete Step",
   "button.error": "Error",
   "button.writeNewMessage": "Write New Message",
   "button.saveChanges": "Save Changes",
   "button.addNewUser": "Add New User",
   "button.more": "More",
   "hint.searchMailList": "Search Mail List",
   "widgets.AcceptorrRejectWithin": "Accept or reject within",
   "widgets.quoteOfTheDay": "Quote Of The Day",
   "widgets.updated10Minago": "Updated 10 min ago",
   "widgets.personalSchedule": "Personal Schedule",
   "widgets.activeUsers": "Active Users",
   "widgets.totalRequest": "Total Request",
   "widgets.new": "New",
   "widgets.ShareWithFriends": "Share with friends!",
   "widgets.helpToShareText": "Help us spread the world by sharing our website with your friends and followers on social media!",
   "widgets.thisWeek": "This Week",
   "widgets.howWouldYouRateUs": "How would you rate us?",
   "widgets.booking": "Booking",
   "widgets.confirmed": "Confirmed",
   "widgets.monthly": "Monthly",
   "widgets.weekly": "Weekly",
   "widgets.target": "Target",
   "widgets.totalActiveUsers": "Total Active Users",
   "sidebar.user": "User",
   "sidebar.miscellaneous": "Miscellaneous",
   "sidebar.promo": "Promo",
   "themeOptions.themeColor": "Theme Color",
   "module.inbox": "Inbox",
   "module.drafts": "Drafts",
   "module.sent": "Sent",
   "module.trash": "Trash",
   "module.spam": "Spam",
   "module.frontend": "Frontend",
   "module.backend": "Backend",
   "module.api": "Api",
   "module.issue": "Issue",
   "components.emailPrefrences": "Email Prefrences",
   "components.myProfile": "My Profile",
   "sidebar.gettingStarted": "Getting Started",
   "widgets.deadline": "Deadline",
   "widgets.team": "Team",
   "widgets.projectManagement": "Project Management",
   "widgets.latestPost": "Latest Post",
   "widgets.projectTaskManagement": "Project Task Management",
   "widgets.selectProject": "Select Project",
   "widgets.activityBoard": "Activity Board",
   "widgets.checklist": "Checklist",
   "sidebar.shop": "Shop",
   "sidebar.cart": "Cart",
   "sidebar.checkout": "Checkout",
   "components.product": "Product",
   "components.quantity": "Quantity",
   "components.totalPrice": "Total Price",
   "components.removeProduct": "Remove Product",
   "components.mobileNumber": "Mobile Number",
   "components.address2Optional": "Address 2 (Optional)",
   "components.country": "Country",
   "components.zip": "Zip",
   "components.saveContinue": "Save & Continue",
   "components.placeOrder": "Place Order",
   "components.payment": "Payment",
   "components.billingAddress": "Billing Address",
   "components.ShippingAddressText": "Shipping address is the same as billing address.",
   "components.CartEmptyText": "Your Shopping Cart Is Empty!",
   "components.NoItemFound": "No Item Found",
   "components.goToShop": "Go To Shop",
   "components.cardNumber": "Card Number",
   "components.expiryDate": "Expiry Date",
   "components.cvv": "CVV",
   "components.nameOnCard": "Name On Card",
   "components.confirmPayment": "Confirm Payment",
   "sidebar.saas": "SAAS",
   "sidebar.multiLevel": "MultiLevel",
   "sidebar.level1": "Level 1",
   "sidebar.level2": "Level 2",
   "sidebar.boxed": "Boxed",
   "sidebar.extensions": "Extensions",
   "sidebar.imageCropper": "Image Cropper",
   "sidebar.videoPlayer": "Video Player",
   "sidebar.dropzone": "Dropzone",
   "widgets.baseConfig": "Base Config",
   "widgets.customControlBar": "Custom Control Bar",
   "widgets.withDownloadButton": "With Download Button",
   "widgets.httpLiveStreaming": "HTTP Live Streaming",
   "widgets.keyboardShortcuts": "Keyboard Shortcuts",
   "button.useDefaultImage": "Use Default Image",
   "button.cropImage": "Crop Image",
   "widgets.preview": "Preview",
   "widgets.croppedImage": "Cropped Image",
   "widgets.transactionList": "Transaction List",
   "widgets.transferReport": " Transfer Report",
   "widgets.expenseCategory": "Expense Category",
   "widgets.upcomingEvents": "Upcoming events",
   "widgets.OngoingProjects": "Ongoing Projects",
   "widgets.ProjectStatus": "Project Status",
   "widgets.LiveChatSupport": "Live Chat Support",
   "sidebar.projects": "projects",
   "sidebar.projectDetail": "project Detail",
   "sidebar.clients": "clients",
   "sidebar.reports": "reports",
   // SME MANAGER
   "sidebar.a": "a",
   "sidebar.add": "add",
   "sidebar.list": "list",

   //voting ballot
   "sidebar.voting-ballot": "Boletas",
   "sidebar.votingBallot": "boletas",
   "sidebar.votingBallotTownPresident": "Nueva boleta",

   //vote projection
   "sidebar.vote-projection": "Proyección del voto",
   "sidebar.voteProjection": "proyección",
   "sidebar.vote-projection-add": "Nueva proyección del voto",

   // fiscalizacion
   "sidebar.fiscalizacion": "Fiscalización",
   "sidebar.bards": "Bardas",
   "sidebar.tarps": "Lonas",
   "sidebar.production": "Producción",

   //votes
   "sidebar.votes": "Chunks",
   //Footer
   "sidebar.footer-text": "Elecciones 06 de Junio 2021",
   //dashboard
   "dashboard.votes": "Votos",
   "dashboard.votesTotal": "Total",
   "dashboard.chunksTotal": "Chunks capturados"
}

export default dic;
