/**
 * Sidebar Content
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListSubheader } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'Util/IntlMessages';
import NavMenuItem from './NavMenuItem';
// redux actions
import { onToggleMenu } from 'Store/Actions';

function SidebarContent(){
   const dispatch = useDispatch();
   const sidebar = useSelector(state => state.sidebar);
   const { sidebarMenus } = sidebar;

	const toggleMenu = (menu, stateCategory) => {
		let data = { menu, stateCategory }
		dispatch(onToggleMenu(data));
	}

   return (
      <div className="rct-sidebar-nav">
         <nav className="navigation">
            {sidebarMenus.category1 &&
               <List
                  className="rct-mainMenu p-0 m-0 list-unstyled"
               >
                  {sidebarMenus.category1.map((menu, key) => (
                     <NavMenuItem
                        menu={menu}
                        key={key}
                        onToggleMenu={() => toggleMenu(menu, 'category1')}
                     />
                  ))}
               </List>
            }
         </nav>
      </div>
   );
}

export default withRouter(SidebarContent);
